/**
 * usage: <input type="text" data-bind="date: dateOfBirth, format: 'DD/MM/YYYY HH:mm:ss'" />
 * requires: moment.js
 */
ko.bindingHandlers.date = {
    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        var value = valueAccessor();
        var allBindings = allBindingsAccessor();
        var valueUnwrapped = ko.utils.unwrapObservable(value);

        // Date formats: http://momentjs.com/docs/#/displaying/format/
        var pattern = allBindings.format || 'DD/MM/YYYY HH:mm:ss';
        var locale = allBindings.locale || 'en-gb';
        var relative = !!allBindings.relative;
        var calendar = !!allBindings.calendar;

        var output = "-";
        if (valueUnwrapped !== null && valueUnwrapped !== undefined) {
            var date = null;

            if (isNaN(valueUnwrapped) && valueUnwrapped.length > 0)
                date = moment(valueUnwrapped).locale(locale);
            else if (parseInt(valueUnwrapped) > 0)
                date = moment.unix(parseInt(valueUnwrapped)).locale(locale);

            if (date) {
                if (relative)
                    output = date.fromNow();
                else if (calendar) {
                    output = date.calendar();
                } else {
                    output = date.format(pattern)
                }
            }
        }


        if ($(element).is("input") === true) {
            $(element).val(output);
        } else {
            $(element).text(output);
        }
    }
};